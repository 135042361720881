import {
  PAYMENT_KIND_IBAN,
  PAYMENT_KIND_LYDIA,
  PAYMENT_KIND_PAYPAL
} from '~/components/features/user/profile/Loaded.vue'

import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_COLLECTOR,
  PROGRAM_KIND_FIDZ,
  PROGRAM_KIND_CASHBACK
} from '~/store/program/state.js'

export default {
  paymentMethods: {
    title: 'Méthodes de paiement (par app)',
    hintForLegacy: `La méthode de paiement "legacy" est identique pour toutes les app (car liée à l'utilisateur et non au compte de chaque app).`,
    none: 'Aucune méthode de paiement enregistrée',
    addOrReplacePaymentMethod: 'ajouter/remplacer une méthode de paiement',
    legacy: 'legacy',
    authenticationDone: 'Authentification faite',
    authenticationPending: 'Authentification en attente',
    paymentMethodAdded: 'Méthode de paiement ajoutée',
    authenticatePaymentMethod: 'Authentifier la méthode de paiement',
    paymentMethodAuthenticated: 'Méthode de paiement authentifiée',
    sendMailForPaymentMethodAuthentication:
      "Envoyer le mail d'authentification de la méthode de paiement",
    mailForPaymentMethodAuthenticationSent:
      "Mail d'authentification de la méthode de paiement envoyé",

    labels: {
      editorId: 'App',
      userFirstName: "Prénom de l'utilisateur",
      userLastName: "Nom de famille de l'utilisateur",
      kind: 'Type de méthode',
      identifier: {
        generic: 'Identifiant',
        [PAYMENT_KIND_IBAN]: 'IBAN',
        [PAYMENT_KIND_LYDIA]: 'Numéro de téléphone Lydia',
        [PAYMENT_KIND_PAYPAL]: 'Email PayPal'
      }
    }
  },

  potAndTransfers: {
    title: 'Cagnotte et transferts'
  },
  pot: {
    currentAmount: 'Montant actuel en cagnotte',
    cumulatedAmount: 'Montant cumulé depuis inscription',
    seeDetails: 'Voir détail',

    modal: {
      title: 'Détails des transferts',
      currentlyInMyPot: 'Actuellement dans ma cagnotte',
      transferRequest: 'Demande de virement',
      transferedOnYourAccount: 'transférés sur votre compte',
      noRefund: 'aucun remboursement',

      kind: {
        BankAccount: 'Virement bancaire',
        PaypalAccount: 'Virement PayPal',
        LydiaAccount: 'Virement Lydia'
      }
    }
  },
  forgettableBankAccount: {
    modalTitle: 'Détails des transferts',
    currentlyPending: "Actuellement en attente d'authentification",
    explanationText:
      "Cliquer sur le cadenas pour accepter l'iban de cet utilisateur",
    validateForgettableBankAccount: 'Ibans en attente',
    successedRequest: "L'iban a bien été accepté pour cet utilisateur",
    failedRequest:
      "Une erreur est survenue à l'enregistrement des informations personnelles"
  },

  table: {
    headers: {
      program_name: 'Programme',
      total_quantity: 'Produits achetés',
      total_price: '(montant)',
      total_benefits_earned: 'Bénéfices gagnés',
      total_benefits_burned: 'Bénéfices dépensés',
      total_refunds_count: 'Produits remboursés',
      total_refunded_amount: '(montant)',
      actions: 'actions'
    },
    benefits: {
      [PROGRAM_KIND_POINTS_VIA_PRICE]: '0 pt | {count} pt | {count} pts',
      [PROGRAM_KIND_POINTS_BY_PURCHASE]: '0 pt | {count} pt | {count} pts',
      [PROGRAM_KIND_STAMPS]: '0 💮 | {count} 💮 | {count} 💮',
      [PROGRAM_KIND_PUZZLE]: '0 🧩 | {count} 🧩 | {count} 🧩',
      [PROGRAM_KIND_COLLECTOR]: '0 🧩 | {count} 🧩 | {count} 🧩',
      [PROGRAM_KIND_FIDZ]: '0 fidz | {count} fidz | {count} fidz',
      [PROGRAM_KIND_CASHBACK]: '0 € | {count} € | {count} €'
    },
    actions: {
      rollbackFidzExpiry: "Annuler l'expiration des Fidz",
      rollbackFidzExpiryDoneWithSuccess: 'Expiration des Fidz annulée'
    }
  },

  earnsAndBurns: {
    headers: {
      origin: 'origine',
      datetime: 'date et heure',
      amountEarned: 'montant gagné',
      withoutCapping: 'sans capping',
      amountBurned: 'montant dépensé',
      balance: 'balance'
    },

    isCashbackFrozen: {
      on: 'cashback gelé',
      off: 'cashback possible',

      updated: 'mis à jour avec succès',
      error: 'une erreur est survenue'
    }
  },

  earnedCoupons: {
    title: "bons d'achat",
    pending: {
      title: "bons d'achat disponibles",
      none: 'aucun disponible'
    },
    used: {
      title: "bons d'achat utilisés",
      headers: {
        status: 'statut',
        coupon: 'coupon',
        progression: 'progression',

        origin: 'origine',
        offer: 'offre',
        acquiredAt: 'acquis {at}',
        afterRejectionOfEarnedCoupon: "après rejet du bon d'achat #{id}",
        validation: 'validation',
        usedAt: 'utilisé {at}',
        cancelledAt: 'annulé {at}',
        receipt: 'ticket',
        purchase: 'achat',
        reselledFor: 'revendu pour {price}'
      },
      fetchMore: 'chargement des bons suivants'
    }
  },

  specialOperations: 'Opérations spéciales'
}
