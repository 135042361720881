import Vue from 'vue'
import { cloneDeep } from 'lodash-es'
import moment from 'moment'

import createDefaultState from './state'

import {
  PAGINATION_KIND_ONE_QUESTION_BY_PAGE,
  PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE,
  QUESTION_KIND_FREE_TEXT,
  QUESTION_KIND_MULTIPLE_CHOICE,
  QUESTION_KIND_RATINGS
} from '@/utils/constants/quizzes.ts'
import hasOwnProp from '@/utils/functions/hasOwnProp'

const DEFAULT_MINIMUM_CHOICE_COUNT = 1
const DEFAULT_MAXIMUM_CHOICE_COUNT = 1

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

const createAnswer = () => ({
  text: '',
  imageUrl: null,
  imageFile: null
})

const createQuestion = () => ({
  title: '',
  kind: QUESTION_KIND_FREE_TEXT,

  answers: [],
  correctAnswerIndex: null,

  minChoiceCount: DEFAULT_MINIMUM_CHOICE_COUNT,
  maxChoiceCount: DEFAULT_MAXIMUM_CHOICE_COUNT,
  minRating: 1,
  maxRating: 5,

  imageUrl: '',
  imageFile: null,

  veryBigText: false,
  isSurvey: false,
  hideText: false,
  answersHaveImages: false
})

const createEmptyPage = () => ({
  title: '',
  questions: []
})

const createDefault = () => ({
  id: null,

  timelineTitle: '',
  timelineContent: '',
  timelineImageFile: null,
  timelineImageUrl: null,

  startedAt: null,
  endedAt: null,

  skipIntroPage: false,
  // suggestProductFromTastes: false,
  displayRectangleChoices: false,
  isSurvey: false,
  skipLogo: false,

  headerImageFile: null,
  headerImageUrl: null,

  // timeline_message
  // identifier

  paginationKind: PAGINATION_KIND_ONE_QUESTION_BY_PAGE,
  pages: [createEmptyPage()],

  hasRefundBenefit: false,
  refundBenefitIdentifier: null,
  hasBonusBenefit: false,
  bonusBenefitIdentifier: null,
  hasPointsBenefit: false,
  pointsBenefitCount: null
})

export function initWithDefault(state, _record) {
  state.record = null
  state.original = createDefault()
  state.modified = cloneDeep(state.original)

  state.isLoaded = true
}

function translateBackendQuestionKind(question) {
  if (question.free_text) return QUESTION_KIND_FREE_TEXT
  if (question.ratings) return QUESTION_KIND_RATINGS

  return QUESTION_KIND_MULTIPLE_CHOICE
}

function translateBackendQuestion(question) {
  return {
    title: question.question,
    kind: translateBackendQuestionKind(question),

    answers: question.answers.map((answer, idx) => ({
      text: answer,
      imageUrl: (question.image_urls || [])[idx],
      imageFile: null
    })),
    correctAnswerIndex: question.correct_answer_index,

    minChoiceCount: question.min_choice_count || DEFAULT_MINIMUM_CHOICE_COUNT,
    maxChoiceCount: question.max_choice_count || DEFAULT_MAXIMUM_CHOICE_COUNT,
    minRating: question.min_rating,
    maxRating: question.max_rating,

    imageUrl: question.image_url,
    imageFile: null,

    veryBigText: question.very_big_text,
    isSurvey: question.is_survey,
    hideText: question.hide_text,
    answersHaveImages: (question.image_urls || []).length > 0
  }
}

function translateBackendPayload(payload) {
  const res = createDefault()

  res.timelineTitle = payload.data.timeline_title
  res.timelineContent = payload.data.timeline_content
  if (payload.data.secure_image_url) {
    res.timelineImageUrl = payload.data.secure_image_url
  }

  if (payload.started_at) {
    res.startedAt = moment(payload.started_at)
  }
  if (payload.ended_at) {
    res.endedAt = moment(payload.ended_at)
  }

  res.skipIntroPage = payload.data.skip_intro_page || false
  // res.suggestProductFromTastes = payload.data.suggest_product_from_tastes || false
  res.displayRectangleChoices = payload.data.display_rectangle_choices || false
  res.isSurvey = payload.data.is_survey || false
  res.skipLogo = payload.data.skip_logo || false

  if (payload.data.header_image_url) {
    res.headerImageUrl = payload.data.header_image_url
  }

  if (payload.data.question_pages) {
    res.paginationKind = PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE
    let start = 0
    res.pages = payload.data.question_pages.map((page) => {
      const end = start + page.count
      const questions = payload.data.questions.slice(start, end)
      start = end
      return {
        title: page.title,
        questions: questions.map((question) =>
          translateBackendQuestion(question)
        )
      }
    })
  } else {
    res.paginationKind = PAGINATION_KIND_ONE_QUESTION_BY_PAGE
    res.pages = [
      {
        title: '',
        questions: payload.data.questions.map((question) =>
          translateBackendQuestion(question)
        )
      }
    ]
  }

  if (payload.refund_benefit_identifier) {
    res.hasRefundBenefit = true
    res.refundBenefitIdentifier = payload.refund_benefit_identifier
  }
  if (payload.bonus_benefit_identifier) {
    res.hasBonusBenefit = true
    res.bonusBenefitIdentifier = payload.bonus_benefit_identifier
  }
  if (payload.data.points) {
    res.hasPointsBenefit = true
    res.pointsBenefitCount = payload.data.points
  }

  return res
}

const useRecord = (state, record) => {
  state.record = cloneDeep(record)
  state.original = translateBackendPayload(state.record)
  state.modified = cloneDeep(state.original)
}

export function initWithRecord(state, record) {
  useRecord(state, record)
  state.modified.id = record.id

  state.isLoaded = true
}

export function duplicateRecord(state, record) {
  useRecord(state, record)
  state.modified.id = null

  state.isLoaded = true
}

export function addPage(state) {
  state.modified.pages.push(createEmptyPage())
}
export function removeLastPage(state) {
  state.modified.pages.splice(state.modified.pages.length - 1, 1)
}

export function addQuestionToPage(state, pageIndex) {
  state.modified.pages[pageIndex].questions.push(createQuestion())
}
export function removeQuestionFromPage(state, { pageIndex, questionIndex }) {
  state.modified.pages[pageIndex].questions.splice(questionIndex, 1)
}

function recursiveUpdate(state, keys, value) {
  if (keys.length === 1) {
    Vue.set(state, keys[0], value)
    return
  }

  const [head, ...tail] = keys
  if (!hasOwnProp(state, head)) {
    Vue.set(state, head, typeof head === 'number' ? [] : {})
  }

  recursiveUpdate(state[head], tail, value)
}

export function deepUpdate(state, { keys, value }) {
  recursiveUpdate(state.modified, keys, value)
}

export function addAnswer(state, { pageIndex, questionIndex }) {
  const page = state.modified.pages[pageIndex]
  if (!page) return

  const question = page.questions[questionIndex]
  if (!question) return

  question.answers.push(createAnswer())
}

export function spliceAnswer(state, { pageIndex, questionIndex, answerIndex }) {
  const page = state.modified.pages[pageIndex]
  if (!page) return

  const question = page.questions[questionIndex]
  if (!question) return

  question.answers.splice(answerIndex, 1)
}
