export default {
  '/metrics/canals/onboarding_funnel': 'Onboarding',
  '/metrics/canals/points_of_sale': 'Points de ventes',
  '/metrics/canals/general': 'Canaux',

  '/users/segments': 'Segments',
  '/users/transfers': 'Virements',

  '/animation/offers': 'Offres',
  '/animation/offers/refund': 'Offres',
  '/animation/offers/bonus': 'Offres',
  '/animation/offers/grouped': 'Offres',
  '/animation/quizzes': 'Quiz',
  '/animation/messages': 'Messages',
  '/animation/messages/emails': 'Messages',
  '/animation/messages/messages': 'Messages',
  '/animation/campaigns': 'Campagnes',
  '/animation/stats_pushes': 'Statistiques des Pushs',

  '/programs/rules': 'Règles',
  '/programs/classification': 'Classification des libellés',
  '/programs/classification-v2': 'Classification des libellés (v2)',

  '/sources/products-collection': 'Collection de produits',

  '/analytics_jobs': 'Extractions de données',
  '/administrators/managers': 'Gérer les administrateurs',

  '/select-program': 'Sélectionner un programme',
  '/create-program': 'Créer un programme',

  '/error/403': '403 Non autorisé'
}
