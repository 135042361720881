





















































































































import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'

import pascalCase from '~/utils/functions/pascalCase'

interface Sizes {
  width: number
  height: number
}

export default Vue.extend({
  name: 'UploadImageFileOrUrl',
  components: {
    ValidationProvider
  },
  props: {
    title: {
      type: String,
      required: true
    },
    titleTag: {
      type: String,
      default: 'h3'
    },
    imageName: {
      type: String,
      required: true
    },

    templateUrl: {
      type: String,
      default: null
    },

    imageFile: {
      type: File,
      default: null
    },
    imageUrl: {
      type: String,
      default: null
    },
    imagePreview: {
      type: String,
      default: null
    },
    imageSquared: {
      type: Boolean,
      default: false
    },
    minSize: {
      type: Number,
      default: null
    },
    minSizeAsWarning: {
      type: Number,
      default: null
    },
    maxWidth: {
      type: Number,
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },

    removable: {
      type: Boolean,
      default: false
    },
    shouldRemove: {
      type: Boolean,
      default: false
    },

    validateWidth: {
      type: Number,
      default: null
    },
    validateHeight: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      localShouldRemove: this.shouldRemove,
      height: 200,
      error: null as null | string,
      sizes: null as null | Sizes
    }
  },
  computed: {
    displayFields() {
      return this.disabled || this.readonly || !this.localShouldRemove
    },
    rulesOnSizes(): string {
      if (!this.imageFile && !this.imageUrl) return ''

      const rules: string[] = []
      if (this.imageSquared) rules.push('image_squared')
      if (this.minSize) rules.push(`image_min_size:${this.minSize}`)
      if (this.maxWidth) rules.push(`image_max_width:${this.maxWidth}`)
      if (this.validateWidth) rules.push(`image_width:${this.validateWidth}`)
      if (this.validateHeight) rules.push(`image_height:${this.validateHeight}`)
      return rules.join('|')
    },
    isMinSizeWarningDisplayed(): boolean {
      if (!this.imageFile && !this.imageUrl) return false
      if (!this.minSizeAsWarning) return false
      if (!this.sizes) return false

      return (
        this.minSizeAsWarning > this.sizes.width ||
        this.minSizeAsWarning > this.sizes.height
      )
    },
    formattedImageName(): string {
      return pascalCase(this.imageName)
    }
  },
  watch: {
    imagePreview(value) {
      this.error = null

      if (value) return

      this.sizes = null
    },
    shouldRemove(value) {
      this.localShouldRemove = value
    }
  },
  methods: {
    setSizes() {
      const img = this.$refs.img as HTMLImageElement

      // `img` can be `null` if component destroyed
      // ex : error occurs and layout error is displayed
      if (img) {
        this.sizes = {
          width: img.naturalWidth,
          height: img.naturalHeight
        }
      }
    },
    onError() {
      this.error = this.$t('global.image.linkIsNotAnImage') as string
    }
  }
})
