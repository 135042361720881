// those strings can not be changed
// they are dependant on the backend
export const USECASE_CAMPAIGNS = 'campaigns'
export const USECASE_SEGMENTS = 'segments'
export const USECASE_EMAILS = 'emails' // TODO: refactor those strings
export const USECASE_MESSAGES = 'messages' // TODO: refactor those strings
export const USECASE_REFUND_OFFERS = 'refund_offers'
export const USECASE_BONUS_OFFERS = 'bonus_offers'
export const USECASE_GROUPED_OFFERS = 'grouped_offers'
export const USECASE_QUIZZES = 'quizzes'
