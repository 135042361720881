// import moment from 'moment'
import { flatMap } from 'lodash-es'

// import { momentFormat } from '~/plugins/filters'

import {
  // PAGINATION_KIND_ONE_QUESTION_BY_PAGE,
  PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE,
  QUESTION_KIND_FREE_TEXT,
  QUESTION_KIND_MULTIPLE_CHOICE,
  QUESTION_KIND_RATINGS
} from '@/utils/constants/quizzes.ts'

export const pagesCount = (state) =>
  state.modified && state.modified.pages.length

export const isMultiplePages = (state) =>
  state.modified &&
  state.modified.paginationKind === PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE

export const hasMoreThanOnePage = (state) =>
  state.modified && state.modified.pages.length > 1

export const payloadForBackend = (state) => {
  const res = {
    attributes: {
      started_at: state.modified.startedAt?.toISOString(),
      ended_at: state.modified.endedAt?.toISOString(),
      benefits: {},
      data: {
        timeline_title: state.modified.timelineTitle,
        timeline_content: state.modified.timelineContent,
        image_url: state.modified.timelineImageUrl,
        image_file: state.modified.timelineImageFile,
        skip_intro_page: state.modified.skipIntroPage,
        suggest_product_from_tastes: state.modified.suggestProductFromTastes,
        display_rectangle_choices: state.modified.displayRectangleChoices,
        is_survey: state.modified.isSurvey,
        skip_logo: state.modified.skipLogo,
        header_image_url: state.modified.headerImageUrl,
        header_image_file: state.modified.headerImageFile
      }
    }
  }

  const relevantQuestions =
    state.modified.paginationKind === PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE
      ? flatMap(state.modified.pages, (page) => page.questions)
      : state.modified.pages[0].questions

  res.attributes.data.questions = relevantQuestions.map((question) => {
    const desc = {
      question: question.title,

      answers: question.answers.map((answer) => answer.text),
      answers_images: question.answers.map((answer) => ({
        image_url: answer.imageUrl,
        image_file: answer.imageFile
      })),
      correct_answer_index: question.correctAnswerIndex,

      min_choice_count: question.minChoiceCount,
      max_choice_count: question.maxChoiceCount,
      min_rating: question.minRating,
      max_rating: question.maxRating,

      image_url: question.imageUrl,
      image_file: question.imageFile,

      very_big_text: question.veryBigText,
      hide_text: question.hideText,
      is_survey: question.isSurvey
    }
    switch (question.kind) {
      case QUESTION_KIND_FREE_TEXT:
        desc.free_text = true
        break
      case QUESTION_KIND_RATINGS:
        desc.ratings = true
        break
      case QUESTION_KIND_MULTIPLE_CHOICE:
      // nothing needed
    }
    return desc
  })

  if (
    state.modified.paginationKind === PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE
  ) {
    res.attributes.data.question_pages = state.modified.pages.map((page) => ({
      title: page.title,
      count: page.questions.length
    }))
  }

  if (state.modified.hasRefundBenefit) {
    res.attributes.benefits.refund_identifier =
      state.modified.refundBenefitIdentifier
  }
  if (state.modified.hasBonusBenefit) {
    res.attributes.benefits.bonus_identifier =
      state.modified.bonusBenefitIdentifier
  }
  if (state.modified.hasPointsBenefit) {
    res.attributes.benefits.points = state.modified.pointsBenefitCount
  }

  return res
}
