<template>
  <div class="benefits">
    <v-data-table
      class="benefits__table"
      :headers="BENEFITS_HEADERS"
      :items="items"
      item-class="rowClass"
      disable-pagination
      hide-default-footer
      :locale="$i18n.locale"
      @click:row="openEarnsBurnsDetails"
    >
      <template #item.program_name="{ item }">
        <v-avatar v-if="item.program_roundable_image_url" tile>
          <v-img
            :src="item.program_roundable_image_url"
            :alt="item.program_name"
          />
        </v-avatar>
        <span v-else>
          {{ item.program_name }}
        </span>
      </template>
      <template #item.total_price="{ item }">
        {{ item.total_refunded_amount | money({ suffix: ' €' }) }}
      </template>
      <template #item.total_benefits_earned="{ item }">
        <BenefitsTableValue
          :program-kind="item.program_kind"
          :value="item.total_benefits_earned"
        />
      </template>
      <template #item.total_benefits_burned="{ item }">
        <BenefitsTableValue
          :program-kind="item.program_kind"
          :value="item.total_benefits_burned"
        />
      </template>
      <template #item.total_refunded_amount="{ item }">
        {{ item.total_refunded_amount | money({ suffix: ' €' }) }}
      </template>

      <template #item.actions="{ item }">
        <v-tooltip v-if="item.program_kind === PROGRAM_KIND_FIDZ" top>
          <template #activator="{ on, attrs }">
            <div class="d-inline-block" v-bind="attrs" v-on="on">
              <v-btn
                icon
                :loading="isRollbacking"
                @click.prevent.stop="rollbackFidzExpiry"
              >
                <v-icon small>
                  mdi-timer-cancel-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>
            {{ $t('pages.user.profile.table.actions.rollbackFidzExpiry') }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <BenefitsModal
      v-if="selectedItem"
      :program-name="selectedItem.program_name"
      :program-kind="selectedItem.program_kind"
      :program-identifier="selectedItem.identifier"
      @close="selectedItem = null"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import BenefitsModal from './BenefitsModal'
import BenefitsTableValue from './BenefitsTableValue'

import { PROGRAM_KIND_FIDZ } from '@/store/program/state'
import { fetchCoreFromComponentAndHandleError } from '@/utils/functions/fetch-and-handle-errors'

const KEYS_WITHOUT_PROGRAM_NAME = [
  'total_quantity',
  'total_price',
  'total_benefits_earned',
  'total_benefits_burned',
  'total_refunds_count',
  'total_refunded_amount',
  'actions'
]
const KEYS_WITH_PROGRAM_NAME = ['program_name'].concat(
  KEYS_WITHOUT_PROGRAM_NAME
)

const KEYS_FLOATS = ['total_price', 'total_refunded_amount']
const sortFloats = (a, b) => parseFloat(a) - parseFloat(b)

export default {
  components: {
    BenefitsModal,
    BenefitsTableValue
  },
  props: {
    benefits: {
      type: Array,
      required: true
    }
  },
  data() {
    const keys =
      this.benefits.length > 1
        ? KEYS_WITH_PROGRAM_NAME
        : KEYS_WITHOUT_PROGRAM_NAME
    return {
      BENEFITS_HEADERS: keys.map((key) => ({
        text: this.$t(`pages.user.profile.table.headers.${key}`),
        value: key,
        align: key === 'program_name' ? 'center' : 'end',
        sort: KEYS_FLOATS.includes(key) ? sortFloats : undefined
      })),
      selectedItem: null,

      PROGRAM_KIND_FIDZ,
      isRollbacking: false
    }
  },
  computed: {
    items() {
      return this.benefits.map((benefit) => ({
        ...benefit,
        rowClass: 'clickable'
      }))
    }
  },
  methods: {
    openEarnsBurnsDetails(item, _$vuetify) {
      this.selectedItem = item
    },

    ...mapMutations('snackbar', ['addToast']),

    rollbackFidzExpiry() {
      fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isRollbacking',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/users/${this.$route.params.userId}/rollback_fidz_expiry`
        },

        onSuccess: () => {
          this.addToast({
            text: this.$t(
              'pages.user.profile.table.actions.rollbackFidzExpiryDoneWithSuccess'
            )
          })
        }
      })
    }
  }
}
</script>
