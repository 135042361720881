export const PAGINATION_KIND_ONE_QUESTION_BY_PAGE = 'ONE_QUESTION_BY_PAGE'
export const PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE = 'GROUP_QUESTIONS_BY_PAGE'

export const PAGINATION_KINDS = [
  PAGINATION_KIND_ONE_QUESTION_BY_PAGE,
  PAGINATION_KIND_GROUP_QUESTIONS_BY_PAGE
]

export const QUESTION_KIND_FREE_TEXT = 'FREE_TEXT'
export const QUESTION_KIND_MULTIPLE_CHOICE = 'MULTIPLE_CHOICE'
export const QUESTION_KIND_RATINGS = 'RATINGS'

export const QUESTION_KINDS = [
  QUESTION_KIND_FREE_TEXT,
  QUESTION_KIND_MULTIPLE_CHOICE,
  QUESTION_KIND_RATINGS
]
