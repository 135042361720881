// defined by backend

export const KIND_BAN_START = 'ban_start'
export const KIND_BAN_END = 'ban_end'
export const KIND_CARD_RENEWAL_APPROVAL = 'card_renewal_approval'
export const KIND_CARD_RENEWAL_PROPOSAL = 'card_renewal_proposition'
export const KIND_CARD_RENEWAL_REJECTION = 'card_renewal_rejection'
export const KIND_DECLARATION = 'declaration'
export const KIND_EARNED_COUPON = 'earned_coupon'
export const KIND_LOYALTY_REWARD_USAGE = 'loyalty_reward_usage'
export const KIND_OPTIN = 'optin'
export const KIND_OPTOUT = 'optout'
export const KIND_FROZEN_EXPIRATION = 'frozen_expiration'
export const KIND_ZENDESK = 'zendesk'
